import cookie from 'js-cookie';
export default {
    state: () => (
        {
            token: ""
        }),
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
            cookie.set("x-api-key", token, { expires: 1, secure: true });
        },

        REMOVE_TOKEN: (state) => {
            state.token = null;
            cookie.remove("x-api-key");
        }
    },
    actions: {
        setToken({ commit }, token) {
            commit("SET_TOKEN", token);
        },
        signOut({ commit }) {
            commit("REMOVE_TOKEN");
        }
    },
    modules: {},
    getters: {
        ISLOGINED: (state) => {
            if (state.token === "")
            {
                return (cookie.get("x-api-key") != "" && cookie.get("x-api-key"))
            }
            return (state.token != "" && state.token);
        }
    },
};
