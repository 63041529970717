<template>
  <div class="container">
    <h3>Пошук товару на складі</h3>
    <div class="input-group">
        <QRCodeButton v-if="!loading" class="form-control btn btn-outline-success" v-on:onDecode="onDecode" />
        <input v-model="filter.code" type="number" class="form-control" placeholder="Код" aria-describedby="basic-addon2">
        <input v-model="filter.size" type="number" min="35" max="42" class="form-control" placeholder="Розмір" aria-describedby="basic-addon2">
    </div>
    <ProductWarehouseDetail v-if="entity" class="mt-2" v-bind:product="entity" v-bind:uri="'/api/productwarehouse'"/>
  </div>
</template>

<script>
import QRCodeButton from '../components/QRCodes/QRCodeButton.vue';
import ProductWarehouseDetail from '../components/Products/ProductWarehouseDetail.vue';
import ProductWarehouse from '../components/Products/Models/ProductWarehouse';
import httpException from '../mixins/httpException';

export default {
    mixins:[httpException],
    name: 'productwarehouse',
    components: { QRCodeButton, ProductWarehouseDetail },
    data() {
        return {
            entity: null,
            loading: false,
            filter: {
                code: "",
                size: ""
            }
        };
    },
    watch:{
        "filter.code"(newValue) {
            if(!this.loading&& newValue && this.filter.size && newValue != "" && this.filter.size != "") {
                if(newValue <= 11999 && newValue >= 11001){
                    if(this.filter.size >= 35 && this.filter.size <= 42) {
                        this.fetch();
                    }
                }
            }
        },

        "filter.size"(newValue) {
            if(!this.loading &&newValue && this.filter.code && newValue != "" && this.filter.code != "") {
                if(this.filter.code <= 11999 && this.filter.code >= 11001) {
                    if(newValue >= 35 && newValue <= 42) {
                        this.fetch();
                    }
                }
            }
        }
    },
    methods: {
        fetch() {
            this.loading = true;
            return this.$http.get("/api/productwarehouse?code="+this.filter.code+"&size="+this.filter.size)
                .then(response => response.data)
                .then(json => {
                    this.loading = false;              
                    this.entity = json ? new ProductWarehouse(json) : null;
                })
                .catch(error => 
                {
                    this.loading = false;
                    this.handleException(error.response);
                });
        },

        onDecode(data) {
            let params = data.split('-');
            let code = new Number(params[0]);
            let size = new Number(params[1]);
            if (code && size) {
                this.filter.code = code;
                this.filter.size = size;
                return;
            }

            params = data.split('/');
            if (params[3] && params[4]) {
                this.filter.code = params[3];
                this.filter.size = params[4];  
                return;
            }
        }
    },
    mounted() {
        let product = this.$route.params.product;
        let size = this.$route.params.size;

        if (product && size) {
            this.filter.code = product;
            this.filter.size = size; 
        }
    }
}
</script>