export default class ProductWarehouse {
    Id = "";
    Code= "";
    WholeSalePrice = "";
    RetailPrice = "";
    IsPromotion = false;
    IsSacking = false;
    Quantity = 0;
    Description = null;
    Size = "";
    Uri = "";

    constructor(pw) 
    {
        this.Id = pw.id;
        this.Code = pw.code;
        this.WholeSalePrice = pw.wholeSalePrice;
        this.RetailPrice = pw.retailPrice;
        this.IsPromotion = pw.isPromotion;
        this.IsSacking = pw.isSacking;
        this.Quantity = pw.quantity;
        this.Description = pw.description;
        this.Size = pw.size;
        this.Uri = pw.uri;
    }

    setQuantity(quantity) {
        this.Quantity = quantity
    }
}