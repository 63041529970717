<template>
    <div class="container">
      <h3>Пошук товарів на складі</h3>
      <div class="input-group">
          <QRCodeButton v-if="!loading" class="form-control btn btn-outline-success" v-on:onDecode="onDecode" />
          <input v-model="filter.code" type="number" class="form-control" placeholder="Код" aria-describedby="basic-addon2">
      </div>
      <ProductDetail v-if="entity" class="mt-2" v-bind:product="entity" v-bind:uri="'/api/productwarehouse'"/>
    </div>
  </template>
  
  <script>
  import QRCodeButton from '../components/QRCodes/QRCodeButton.vue';
  import ProductDetail from '../components/Products/ProductDetail.vue';

  import Product from '../components/Products/Models/Product';
  import httpException from '../mixins/httpException';
  
  export default {
      mixins:[httpException],
      name: 'product',
      components: { QRCodeButton, ProductDetail },
      data() {
          return {
              entity: null,
              loading: false,
              filter: {
                  code: ""
              }
          };
      },
      watch:{
          "filter.code"(newValue) {
              if(!this.loading && newValue && newValue != "" ) {
                  if(newValue <= 11999 && newValue >= 11001){
                    this.fetch();
                  }
              }
          },
      },
      methods: {
          fetch() {
              this.loading = true;
              return this.$http.get("/api/product?code="+this.filter.code)
                  .then(response => response.data)
                  .then(json => {
                      this.loading = false;              
                      this.entity = json ? new Product(json) : [];
                  })
                  .catch(error => 
                  {
                      this.loading = false;
                      this.handleException(error.response);
                  });
          },
  
          onDecode(data) {
              let params = data.split('-');
              let code = new Number(params[0]);
              if (code) {
                  this.filter.code = code;
                  return;
              }
  
              params = data.split('/');
              if (params[3] && params[4]) {
                  this.filter.code = params[3];
                  return;
              }
          }
      },
      mounted() {
          let product = this.$route.params.product;
  
          if (product) {
              this.filter.code = product;
          }
      }
  }
  </script>