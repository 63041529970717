<template>
    <div class="container text-center">
        <div class="container mt-5 pt-5" style="max-width:500px">
            <img src="../assets/logo.png" />
            <div v-if="error" class="alert alert-warning" role="alert"> {{ this.error }} </div>
            <form @submit.prevent="login">
                <div>
                    <label class="d-flex required">Ім'я користувача</label>
                    <div class="form-group justify-content-between">
                        <input id="email" v-model="userName" type="text" class="form-control" required />
                    </div>
                </div>
                <div>
                    <label class="d-flex required">Пароль</label>
                    <div class="form-group">
                        <input id="password" v-model="userPassword" type="password" class="form-control" required />
                    </div>
                </div>
                <div class="form-group" style="margin-top: 5px">
                    <div class="btn-group d-flex" role="group" aria-label="Basic example">
                        <button :disabled="loading" class="btn btn-outline-primary btn-sm btn-block" type="submit">Увійти</button>
                        <QRCodeButton v-if="!loading" class="btn btn-outline-success btn-sm btn-block" v-on:onDecode="onDecode" />
                    </div>
                </div>
            </form>            
        </div>
    </div>
</template>

<script>
    import QRCodeButton from '../components/QRCodes/QRCodeButton.vue';
    export default {
        name: 'v-login',
        components: { QRCodeButton },
        data() {
            return {
                userName: "",
                userPassword: "",
                error: null,
                loading: false
            };
        },
        created() {
            this.$store.dispatch("signOut");
        },
        methods: {
            login() {
                return this.fetch(btoa(this.userName + ":" + btoa(this.userPassword)));
            },

            fetch(credential) {
                this.loading = true;
                return this.$http.post("/api/login", { credential: credential })
                    .then(response => response.data)
                    .then(json => {
                        this.error = null;
                        this.loading = false;
                        this.$store.dispatch("setToken", json.token)
                        this.$router.push({ name: 'Product' });                        
                    })
                    .catch(error => 
                    {
                        this.error = "Не вірно введений логін або пароль";
                        this.loading = false;
                        console.log(error);
                    });
            },

            onDecode(credential) {
                this.fetch(credential);
            }
        },
    }
</script>
<style>
    img {
        max-width: 300px;
        max-height: 300px;
    }

    .group {
        margin-bottom:2px
    }
</style>

