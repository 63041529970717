<template>
    <div class="flex-wrap-container row">
        <div class="col-sm">
            <img class="label-image" src="../../assets/logo.png">
        </div>
        <div class="col-sm-5">
            <div class="mt-2">
                <router-link class="m-1 container" v-for="item in paths" :to="{ path: item.Route }" :key="item.Title">{{ item.Title }}</router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"horizontalNavigation",
    props:{ paths:Array }
}
</script>
<style scoped>
    a {
        color: black;
        font: 15px/24px Helvetica, Sans-Serif;
        text-decoration: none
    }
    .label-image {
        margin-top: 2px;
        width: 100px;
        height: 50px;
    }
    .flex-wrap-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 0;
        justify-content: center;
    }
</style>