<template>
    <div class="upload-btn-wrapper btn-block">
        Сканувати QR-код
      <qrcode-capture
        width="100%"
        type="file"
        accept="image/png, image/jpeg"
        @decode="onDecode"
      ></qrcode-capture>
    </div>
</template>

<script>
import { QrcodeCapture } from "vue3-qrcode-reader";
export default {
  name: "QRCodeButton",
  emits:["onDecode"],
  components: { QrcodeCapture },
  methods: {
    onDecode(result) {
      this.$emit("onDecode", result);
    },
  },
}
</script>

<style scoped>
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper input[type="file"] {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}
</style>
