export default class ProductSizeWarehouse {
    Id = "";
    Name= "";
    Quantity = 0;
    OrderedQuantity = 0;
    ReservedQuantity = 0;
    
    constructor(pw) 
    {
        this.Id = pw.id;
        this.Name = pw.name;
        this.Quantity = pw.quantity;
        this.OrderedQuantity = pw.orderedQuantity;
        this.ReservedQuantity = pw.reservedQuantity;
    }

    setQuantity(quantity) {
        this.Quantity = quantity
    }
}