<template>
  <div class="card mb-3">
    <div class="row g-0">
      <div class="col-md-4">
        <img :src="product.Uri" class="img-fluid rounded-start">
      </div>
      <div class="col-md">
        <div class="card-body ">
          <h5 class="card-title">{{ product.Code }}</h5>
          <p>Роздрібна ціна: {{ product.RetailPrice }} грн</p>
          <p>Оптова ціна: {{ product.WholeSalePrice }} грн</p>
          <p>Розмір: {{ product.Size}}</p>
          <p>Кількість на складі: {{ product.Quantity }}</p>
          <p class="card-text"><small class="text-muted">{{ product.Description }}</small></p>
        </div>
      </div>
      <form @submit.prevent="change" class="input-group">
        <input v-model="quantity" type="number" :min="-product.Quantity" class="form-control" placeholder="Кількість" aria-describedby="basic-addon2">
        <button type="submit" :disabled="!quantity || quantity == 0 || loading" class="form-control btn btn-outline-success">{{ quantity > 0 ? 'Додати' : 'Списати' }}</button>
      </form>
    </div>
  </div>
</template>

<script>
import httpException from '../../mixins/httpException';
import ProductWarehouse from './Models/ProductWarehouse';
export default {
    name: "productWarehouseDetail",
    mixins:[httpException],
    props: { product: ProductWarehouse, uri:String },
    data() {
      return {
          quantity: null,
          loading: false,
      };
    },
    methods:{
      change() {
        this.fetch({ id: this.product.Id, quantity: this.quantity });
      },

      fetch(body) {
            this.loading = true;
            return this.$http.post(this.uri, body)
                .then(response => response.data)
                .then(json => {
                    this.loading = false;  
                    this.product.setQuantity(json.quantity);            
                })
                .catch(error => 
                {
                    this.loading = false;
                    this.handleException(error.response);
                });
      },
    }
}
</script>

