<template>
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Розмір</th>
                <th scope="col">Резервів</th>
                <th scope="col">Замовлень</th>
                <th scope="col">Кількість</th>
            </tr>
        </thead>
        <tbody>
            <ProductSizesItemDetail v-for="pw in product.Warehouses" :key="pw.Id" v-bind:warehouse="pw" v-bind:uri="uri"/>
        </tbody>
    </table>
</template>

<script>
import Product from './Models/Product';
import ProductSizesItemDetail from './ProductSizesItemDetail.vue';
export default {
    name: "productSizesTableDetail",
    components: { ProductSizesItemDetail },
    props: { product: Product, uri:String }
}
</script>