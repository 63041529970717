import ProductSizeWarehouse from "./ProductSizeWarehouse";

export default class Product {
    Id = "";
    Code = "";
    WholeSalePrice = "";
    RetailPrice = "";
    IsPromotion = "";
    IsSacking = "";
    Description = "";
    Uri = "";
    Warehouses = [];

    constructor(p) 
    {
        this.Id = p.id;
        this.Code = p.code;
        this.WholeSalePrice = p.wholeSalePrice;
        this.RetailPrice = p.retailPrice;
        this.IsPromotion = p.isPromotion;
        this.IsSacking = p.isSacking;
        this.Description = p.description;
        this.Uri = p.uri;
        this.Warehouses = p.warehouses.map(x => new ProductSizeWarehouse(x));
    }
}