<template>
    <div class="card mb-3">
      <div class="row g-0">
        <div class="col-md-4">
          <img :src="product.Uri" class="img-fluid rounded-start">
        </div>
        <div class="col-md">
            <div class="card-body ">
                <h5 class="card-title">{{ product.Code }}</h5>
                <p>Роздрібна ціна: {{ product.RetailPrice }} грн</p>
                <p>Оптова ціна: {{ product.WholeSalePrice }} грн</p>
                <p class="card-text"><small class="text-muted">{{ product.Description }}</small></p>
            </div>
        </div>
      </div>
      <ProductSizesTableDetail v-bind:product="product" v-bind:uri="uri"/>
    </div>
  </template>
  
<script>
import Product from './Models/Product';
import ProductSizesTableDetail from './ProductSizesTableDetail.vue';
export default {
    name: "productDetail",
    components: { ProductSizesTableDetail },
    props: { product: Product, uri:String }
}
</script>
  
  