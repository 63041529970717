import { createWebHistory, createRouter } from "vue-router";
import cookies from 'js-cookie';

import Login from "@/pages/Login.vue";
import Product from "@/pages/Product.vue";
import ProductWarehouse from "@/pages/ProductWarehouse.vue";

const routes = [
    { name: "ProductWarehouse", path: '/', component: ProductWarehouse, meta: { isLogined: true } },
    //{ name: "ProductByProductAndSize", path: '/:product/:size', component: ProductWarehouse, meta: { isLogined: true }
    //{ name: "ProductByCode", path: '/product/:product', component: Product, meta: { isLogined: true } },
    
    { name: "Product", path: '/product', component: Product, meta: { isLogined: true } },
    { name: "ProductByProductAndSize", path: '/:product/:size', component: Product, meta: { isLogined: true } },
    
    { name: "Login", path: '/login', component: Login }   
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const loginedNext = function (next) {
    if (cookies.get("x-api-key")) {
        next();
    } else {
        next({ name: "Login" })
    }
};

router.beforeEach((to, _, next) => {
    if (to.matched.some((record) => record.meta.isLogined)) {
        loginedNext(next);
    } else {
        next();
    }
});
  
export default router;