export default {
    methods: {
        handleException(response) {
            if(response.status == 401){
                this.$router.push({ name: "Login" });
            }
            else if(response.status == 405)
                this.$toast.error("Модуль відключений");
            else {
                this.$toast.error(response.data.message);
            }
        }
    },
}