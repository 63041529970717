import "bootstrap/dist/css/bootstrap.min.css"
import { createApp } from 'vue'
import { createStore } from 'vuex'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import cookie from 'js-cookie';
import toaster from '@meforma/vue-toaster';

//=================[Store]==========
import session from "./store/session"

const store = createStore({
    strict: true,
    modules: { session },
})

let app = createApp(App);

app.config.globalProperties.$http = axios;
const token = cookie.get("x-api-key")
if (token) {
    app.config.globalProperties.$http.defaults.headers.common["x-api-key"] = token
}

app.use(toaster, { position: "top"});
app.use(store);
app.use(router).mount('#app')
