<template>
    <tr :id="warehouse.Id">
        <th scope="row">{{ warehouse.Name }}</th>
        <th>{{ warehouse.ReservedQuantity }}</th>
        <th>{{ warehouse.OrderedQuantity }}</th>
        <td>
            <form @submit.prevent="change" class="input-group">
                <input v-model="quantity" type="number" :min="-warehouse.Quantity" class="form-control" :placeholder="warehouse.Quantity" aria-describedby="basic-addon2">
                <button type="submit" :disabled="!quantity || quantity == 0 || loading" class="form-control btn btn-outline-success">{{ quantity > 0 ? 'Додати' : 'Списати' }}</button>
            </form>
        </td>
    </tr>    
</template>

<script>
 import ProductSizeWarehouse from './Models/ProductSizeWarehouse';
  export default {
        name: "productSizeDetail",
        props: { warehouse: ProductSizeWarehouse, uri:String },
        data() {
        return {
            quantity: null,
            loading: false
        };
    },
    methods:{
        change() {
            this.fetch({ id: this.warehouse.Id, quantity: this.quantity });
        },

        fetch(body) {
            this.loading = true;
            return this.$http.post(this.uri, body)
                .then(response => response.data)
                .then(json => {
                    this.loading = false;  
                    this.warehouse.setQuantity(json.quantity);  
                    this.quantity = null;          
                })
                .catch(error => 
                {
                    this.loading = false;
                    this.handleException(error.response);
                });
        },
    }
}
</script>