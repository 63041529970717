<template>
    <div class="container-ms">
        <Navigation v-if="this.$store.getters.ISLOGINED" :paths="paths" :paymentPath="'/payment'" class="nav-border-bottom"></Navigation>
        <router-view></router-view>
        <!--<Footer :supportRouter="supportRouter"></Footer>-->
    </div>
</template>

<script>
    import NavigationModel from './components/Navigations/Navigation.js';
    import Navigation from './components/Navigations/HorizontalNavigation.vue';

    export default {
        name: 'App',
        components: {
            Navigation,
        },
        data() {
            return {
                paths: [
                    new NavigationModel("Склад", "/"),
                    new NavigationModel("Товар", "/product"),
                    new NavigationModel("Вийти", "/login")
                ],
            }
        }
    }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav-border-bottom {
    -webkit-box-shadow: 0 4px 6px -6px #222;
    -moz-box-shadow: 0 4px 6px -6px #222;
    box-shadow: 0 4px 6px -6px #222;
}
</style>
